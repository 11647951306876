<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>

        <v-card v-if="data_loaded">
          <v-data-table
            class="text-center"
            :headers="headers"
            :items="items"
            mobile-breakpoint="0"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:[`item.note`]="{ item }">
              <v-btn icon small @click="show_note(item.note)">
                <v-icon small>visibility</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

        <div
          v-if="data_loaded"
          class="text-center mt-4 d-flex justify-center mb-10"
        >
          <v-pagination
            @input="change_page()"
            v-model="page"
            total-visible="7"
            :length="total"
            circle
          ></v-pagination>
          <div class="mr-2 pt-2">
            <v-select
              :value="perpage"
              :items="pagination"
              style="width: 70px"
              hide-details
              dense
              @change="change_page()"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <v-dialog scrollable v-model="dialog" max-width="500">
      <v-card style="overflow: hidden">
        <v-card-text class="pa-3">
          <v-textarea
            rows="20"
            class="text-end"
            dir="ltr"
            readonly
            v-model="note"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn depressed color="error" @click="dialog = false"> ביטול </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "employees_page",
  data() {
    return {
      items: [],
      data_loaded: false,
      note: "",
      dialog: false,
      headers: [
        {
          value: "action_id",
          text: "מזהה פעולה",
          align: "center",
        },
        {
          value: "type",
          text: "סוג פעולה",
          align: "center",
        },
        {
          value: "table",
          text: "טבלה",
          align: "center",
        },
        {
          value: "created_at",
          text: "טבלה",
          align: "center",
        },
        {
          value: "note",
          text: "הערות",
          align: "center",
        },
      ],
      perpage: 15,
      page: 1,
      total: 0,
      pagination: [15, 20, 30, 50],
    };
  },

  computed: {
    parents_sections() {
      let sections = [];
      this.sections.forEach((e) => {
        if (!e.parent_id) {
          sections.push(e);
        }
      });
      return sections;
    },
  },
  methods: {
    change_page() {
      this.data_loaded = false;
      this.get_data();
    },
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `logs?page=${this.page}&per_page=${this.perpage}`,
            method: "get",
          },
        })
        .then((res) => {
          const data = res.data;
          this.data_loaded = true;
          this.items = Object.assign([], res.data.data);
          this.total = data.last_page;
          this.page = data.current_page;
          
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    show_note(note) {
      this.note = note;

      this.$nextTick(() => {
        this.dialog = true;
      });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
